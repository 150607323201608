import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_watermark = _resolveComponent("van-watermark");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_watermark, {
    content: "测试版",
    opacity: "0.3"
  }), _createVNode(_component_router_view)]);
}